<template>
  <div :class="'skin_theme_' + skin_theme" class="my-order-box">
    <div class="my-order">
      <!-- 订单搜索 -->
      <div class="order-search">
        <div>
          <el-select
            v-model="order_type"
            :placeholder="
              languagePackage[language].userV2_right_right_My_order_search1
            "
            class="mr"
          >
            <el-option
              :label="
                languagePackage[language].userV2_right_right_My_order_web_renew
              "
              value="web_renew"
            >
            </el-option>
          </el-select>

          <el-date-picker
            v-model="start_time"
            type="date"
            :placeholder="
              languagePackage[language].userV2_right_right_My_order_search2
            "
            value-format="timestamp"
          />
          -
          <el-date-picker
            v-model="end_time"
            type="date"
            :placeholder="
              languagePackage[language].userV2_right_right_My_order_search2
            "
          />
        </div>

        <div class="search-btn">
          <!-- <el-button class="reset">
          <div class="btn-info">
            <span class="el-icon-refresh-right icon"></span>
            <span>重置</span>
          </div></el-button
        > -->
          <el-button type="primary" @click="onSearch">
            <div class="btn-info">
              <span class="el-icon-search icon"></span>
              <span>{{
                languagePackage[language].userV2_right_right_My_order_search_btn
              }}</span>
            </div>
          </el-button>
        </div>
      </div>
      <!-- 订单列表 -->
      <div class="order-list">
        <el-table
          :height="tableHeight"
          :data="list"
          border
          style="width: 100%"
          v-loading="loading"
        >
          <el-table-column
            prop="order_num"
            :label="
              languagePackage[language].userV2_right_right_My_order_title1
            "
            width="200"
            align="center"
          />

          <el-table-column
            prop="type_f"
            :label="
              languagePackage[language].userV2_right_right_My_order_title2
            "
            width="128"
            align="center"
          />

          <el-table-column
            prop="fee_f"
            :label="
              languagePackage[language].userV2_right_right_My_order_title3
            "
            width="112"
            align="center"
          />

          <el-table-column
            prop="create_time_f"
            :label="
              languagePackage[language].userV2_right_right_My_order_title4
            "
            width="180"
            align="center"
          />

          <el-table-column
            :label="
              languagePackage[language].userV2_right_right_My_order_title5
            "
            width="100"
            align="center"
          >
            <template slot-scope="scope">
              <span v-if="scope.row.status == 1" style="color: red">{{
                scope.row.status_f
              }}</span>
              <span v-if="scope.row.status == 2" style="color: green">{{
                scope.row.status_f
              }}</span>
            </template>
          </el-table-column>

          <el-table-column
            :label="
              languagePackage[language].userV2_right_right_My_order_title6
            "
            width=""
            header-align="center"
            align="center"
          >
            <template slot-scope="scope">
              <el-button @click="onSee(scope.row)" type="text" size="small">
                {{ languagePackage[language].userV2_right_right_My_order_btn1 }}
              </el-button>
              <!-- <el-button type="text" size="small" class="delete">
               {{languagePackage[language].userV2_right_right_My_order_btn2}}
            </el-button> -->
            </template>
          </el-table-column>
        </el-table>
        <div style="text-align: right; margin: 10px 0">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="page"
            :page-size="page_size"
            @current-change="currentChange"
            :total="count"
          ></el-pagination>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { get_list } from "@/api/order.js";
import languagePackage from "@/languagePackage/index.js";

export default {
  data() {
    return {
      options: [
        {
          value: "web_renew",
          label: "网站续费",
        },
      ],

      order_type: "",
      start_time: "",
      end_time: "",
      page: 1,
      page_size: 10,
      list: [],
      count: 0,
      loading: false,
      tableHeight: document.body.offsetHeight - 71 - 62 - 150,
      languagePackage,
    };
  },
  mounted() {
    this.getList();
  },
  methods: {
    getList() {
      this.loading = true;

      let start_time = 0;
      if (this.start_time) {
        start_time = parseInt(this.start_time / 1000);
      }
      let end_time = 0;
      if (this.end_time) {
        end_time = parseInt(this.end_time / 1000 + 86400);
      }

      let p = {
        order_type: this.order_type,
        start_time,
        end_time,
        page: this.page,
        page_size: this.page_size,
      };
      get_list(p)
        .then((res) => {
          this.list = res.data.list;
          this.count = res.data.count;
        })
        .catch((err) => {})
        .finally(() => {
          // 结束 loadingGet
          this.loading = false;
        });
    },
    onSearch() {
      this.count = 0;
      this.page = 1;
      this.page_size = 10;
      this.list = [];
      this.getList();
    },
    currentChange(page) {
      this.page = page;
      this.getList();
    },

    onSee(order) {
      let order_num = order.order_num;
      this.$router.push(
        "/index/userCenter/order/details?order_num=" + order_num
      );
    },
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
    skin_theme() {
      return this.$store.getters.skin_theme;
    },
  },
};
</script>

<style lang="less" scoped>
.my-order-box {
  padding: 1px;
  border-radius: 14px;
  margin: 0 25px;
  .my-order {
    // width: 100%;
    // height: 100%;
    border-radius: 14px;
    overflow: hidden;
    .order-search{
        padding:25px 20px 20px 20px;
        box-sizing: border-box;
    }
    .order-list {
      padding: 0 20px;
      box-sizing: border-box;
    }
    .order-search {
      display: flex;
      justify-content: space-between;
      /deep/.el-input {
        width: 130px;
        font-size: 12px;
        .el-input__inner {
          height: 26px;
          padding-right: 0 !important;
          border-radius: 2px;
        }
        .el-input__icon {
          line-height: 26px;
          font-size: 12px;
        }
      }
      .el-select {
        width: 152px;
      }
      .el-range-editor {
        width: 342px;
        margin-right: 50px;
        /deep/.el-range-separator {
          padding: 0 !important;
        }
      }
      .mr {
        margin-right: 20px;
      }
      .search-btn {
        .reset {
          border: 1px solid #299ffe;
          color: #299ffe;
        }
        .el-button {
          width: 56px;
          height: 26px;
          padding: 0;
          font-size: 12px;
          //
          .btn-info {
            display: flex;
            align-items: center;
            justify-content: center;
            .icon {
              font-size: 12px;
            }
          }
        }
      }
    }
    .order-list {
      /deep/.el-table th {
        background: #fafbfc;
        font-size: 12px;
        color: #333;
      }
      /deep/.el-table td {
        font-size: 12px;
        color: #666;
      }
      .delete {
        color: #f26f70;
      }
    }
  }
}

.skin_theme_one.my-order-box {
  box-shadow: 0px 5px 10px 0px rgba(0, 18, 153, 0.2);
  background-color: #ffffff;
  .my-order {
    .order-search,
    .order-list {
      background-color: #fff;
    }
    /deep/.el-input {
      .el-input__inner {
        background-color: #fff;
      }
    }
    /deep/.el-table th {
      background-color: #fff;
      color: #333;
    }
    /deep/.el-table td {
      background: #fff;
      color: #333;
    }
    /deep/.el-table__body-wrapper {
      background: #fff;
    }
    .el-button--primary {
      background-color: #ec7a6b;
      border: 0;

      box-shadow: 0px 2px 8px 0px rgba(21, 2, 0, 0.69);
    }
    /deep/.number {
      background: #fafbfc !important;
      color: #333 !important;
    }
    /deep/.number.active {
      background-color: #4943ff !important;
      color: #fff !important;
    }
  }
}
.skin_theme_two.my-order-box {
  background: linear-gradient(
    205deg,
    #24bca8 0%,
    rgba(27, 26, 31, 0.19) 32%,
    #1b1a1f 100%
  );
  box-shadow: 4px 6px 16px 0px #000000;
  .my-order {
    background-color: #232227;
    .order-search,
    .order-list {
      background-color: #232227;
    }
    /deep/.el-input {
      .el-input__inner {
        background-color: #232227;
      }
    }
    /deep/.el-table th {
      background-color: #232227;
      color: #fff;
      border: 0;
    }
    /deep/.el-table td {
      background: #232227;
      color: #fff;
      border-right: 0;
      border-bottom: 1px solid #4d4c50;
    }
    /deep/.el-table__body-wrapper {
      background: #232227;
    }
    .el-button--primary {
      border: 0;
      background: linear-gradient(
        201deg,
        #24bca8 0%,
        rgba(27, 26, 31, 0.19) 32%,
        #1b1a1f 100%
      );
      box-shadow: 4px 6px 16px 0px rgba(0, 0, 0, 0.4);
    }

    /deep/.number {
      background: #232227 !important;
      color: #fff !important;
    }
    /deep/.number.active {
      background-color: #ec7a6b !important;
      color: #fff !important;
    }
    /deep/.el-table--border,
    /deep/.el-table--group {
      border: 0;
    }
    .el-table::before,
    .el-table::after {
      height: 0;
      // background-color: #4d4c50;
    }
  }
}
</style>