import request from '@/utils/request'

export const get_list = (params) => {
    let url = "/index.php/api/v1_0_0.order/get_list";
    return request.post(url, params)
}
export const get_info = (params) => {
    let url = "/index.php/api/v1_0_0.order/get_info";
    return request.post(url, params)
}

