<template>
  <myCenterLayout
    select="order"
    :pageTitle="languagePackage[language].userV2_right_right_myOrder_title"
  >
    <orderList></orderList>
  </myCenterLayout>
</template>

<script>
import myCenterLayout from "@/components/layout/myCenter/index.vue";
import orderList from "./c/orderList.vue";
import languagePackage from "@/languagePackage/index.js";
export default {
  components: {
    myCenterLayout,
    orderList,
  },
  data() {
    return {
      languagePackage,
    };
  },
  computed: {
    language() {
      return this.$store.getters.language;
    },
  },
};
</script>

<style lang="less" scoped>
</style>